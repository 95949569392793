// Primary
$brand: #0D59D1;
$white: #FFFFFF;
$black: #000000;

// Secondary
$accentA: #9037F7;
$accentB: #A2F9E3;
$accentC: #FAD023;
$accentD: #3096FC;
$accentE: #199185;
$accentF: #074742;
$accentG: #00409E;
$accentH: #4B0EA0;
$accentG: #694382;
$accentI: #FFD8B2;
$accentJ: #805B36;
$accentK: #FEEDAF;
$accentL: #8A5340;
$accentM: #FF9839;
$pastelA: #E9D7FD;
$pastelB: #ECCFFF;
$pastelC: #FFCDD2;
$pastelD: #D6EAFE;
$pastelE: #DFEDEA;
$pastelF: #DAE5E2;

// Success
$success: #43C276;
$sucessLight: #006D3A;
$active: #256029;
$activeLight: #C8E6C9;
$paid: #32C66D;
$approved: #689F38;

// Errors
$error:#F95F5F;
$reject: #C63737; 

// Greens
$green1: #D1E9E7;

// Grays
$contentA: #2E2C36;
$contentB: #6A696E;
$contentC: #C1C5CC;
$contentD: #ECEEF1;
$contentE: #F7F8FA;
$darkGray1: #D6D6D6;
$darkGray2: #323341;
$darkGray3: #47495E;
$darkGray4: #686A7D;
$darkGary5: #6C757D;
$darkGray6: #5C626C;

$lightAlert: #FFF2E5;
$alert: #FFA553;

// Dividers
$line: #0D59D1;
$block: #0D59D1;
$skyBlue: #3B82F6;
$nightBlue: #3A3C4D;

//Background colors
$background-gray: #F7F8FA;
$background-dark: #262733;
$background-dark-blue: #3E3F50;

// news
$onSurfey:#191C1B;
$onSurfeyVariant:#3F4947;

//material-theme/surfaces/light/surface1
//material-theme/surfaces/light/surface1

$onSurface1: #006A61;
$onSurface2: #006C51;
$onSurface3: #4A635F;
$onSurfaceLight1: #FAFDFB;

//material-theme/sys/light/secondary-container
//material-theme/sys/light/outline
//material-theme/sys/light/on-secondary-container
$onSecondayContainer: #CCE8E3;
$onOutlineLight: #6F7977;
$onSecondaryContainerTextColor: #05201C;

//material/theme/sys/light/error
$lightError: #BA1A1A;
$lightInvalid: #e24c4c;
$burn: #FC4747;

// Progress Bar
$progressBar: #D8DADC;
$progressBarValue: #607D8B;